<template>
  <div class="p-3">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider name="API Url" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="API Url">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="api_url"
                  v-model="form.api_url"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider name="Mesaj Başlık" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Mesaj Başlık">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="msg_header"
                  v-model="form.msg_header"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider name="Kullanıcı Adı" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Kullanıcı Adı">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="usercode"
                  v-model="form.usercode"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider name="Şifre" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Şifre">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="password"
                  type="password"
                  v-model="form.password"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex">
              <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
                <i class="fad fa-save pr-2"></i>
                {{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}
              </b-button>
              <b-button squared size="lg" variant="danger">
                <i class="fad fa-trash"></i>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
export default defineComponent({
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.form = ref({
      api_url: 'https://api.netgsm.com.tr/sms/send/get/',
      usercode: null,
      password: null,
      msg_header: null,
    });

    const handlerConfig = async () => {
      context.emit('show', true);
      await store.dispatch('smsAyarlariGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit('show', false);
    };

    handlerConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('smsAyarlariEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
